import { useContext } from 'react';

import { ConfigContext, getShopKey } from '@providers/ConfigProvider';
import Typography from '@components/atoms/Typography';
import { FormattedMessage } from 'utils/intl';

import { textMap } from './SearchEngineText.consts';
import classes from './SearchEngineText.module.scss';

const SearchEngineText = (): JSX.Element => {
  const {
    contact: { appName },
    shopId,
  } = useContext(ConfigContext);

  const values = { appName };
  const data = textMap[getShopKey(shopId)];

  return (
    <Typography variant="caption2" renderAs="section" className={classes.wrapper}>
      {data.map(({ header, paragraphs }, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <article key={i} className={classes.article}>
          <FormattedMessage tagName="h3" {...header} values={values} />
          {paragraphs.map((paragraph, j) => (
            // eslint-disable-next-line react/no-array-index-key
            <FormattedMessage key={j} tagName="p" {...paragraph} values={values} />
          ))}
        </article>
      ))}
    </Typography>
  );
};

export default SearchEngineText;
