import { GetServerSideProps, InferGetServerSidePropsType } from 'next';

import config from 'config';
import withShopContext from 'core/hocs/withShopContext';
import withPageLayout from 'core/hocs/withPageLayout';
import { SortId, premiumSerwerSortValues } from 'consts/sort';
import { NumberKey } from 'types/models/ApiNumber';
import { searchWithFiltersRoute } from 'apiRoutes/search';
import {
  GetPageLayoutResult,
  ServerSideProps as PageLayoutServerSideProps,
  serverRequests as pageLayoutServerRequests,
} from '@layouts/PageLayout/PageLayout.utils';
import { getShopKey, ShopKey } from '@providers/ConfigProvider';
import HomePage, { Props as HomePageProps } from '@pages/Home';
import combineRequest, { RequestConfig } from 'utils/combineRequest';
import getComparisonExpression from 'utils/getComparisonExpression';
import { mapMenuToHomepageProducts, getMenuTileData } from 'utils/menuTile';
import graphQLRequest from 'utils/graphQLRequest';
import { startTransaction } from 'utils/startTransaction';
import { serverApiFetch } from 'utils/fetch';
import handleError from 'utils/handleError';
import omit from 'utils/omit';

import { testimonialRequestConfig, GetTestimonialsResult } from 'graphql/queries/GetTestimonials.query';
import {
  trustedUsCountRequestConfig,
  GetTrustedUsCountVariables,
  GetTrustedUsCountResults,
} from 'graphql/queries/GetTrustedUsCount.query';
import {
  variantsPromotedCountRequestConfig,
  GetVariantsPromotedCountVariables,
  GetVariantsPromotedCountResults,
} from 'graphql/queries/GetVariantsPromotedCount.query';
import { pageRequestConfig, GetPageResults, GetPageVariables } from 'graphql/queries/GetPageData.query';
import {
  pageFilesRequestConfig,
  GetPagesFilesResults,
  GetPagesFilesVariables,
} from 'graphql/queries/GetPagesFiles.query';
import {
  articleCategoriesWithArticlesRequestConfig,
  GetArticleCategoriesWithArticlesResult,
  GetArticleCategoriesWithArticlesVariables,
} from 'graphql/queries/GetArticlesCategoriesWithArticles.query';
import { tileMenuRequestConfig, GetTileMenuResult } from 'graphql/queries/GetTileMenu.query';
import { GetNumbersResults, GetNumbersVariables, numbersRequestConfig } from 'graphql/queries/GetNumbers.query';
import { GetBannersResults, bannersRequestConfig } from 'graphql/queries/GetBanners.query';
import { getSearchConstantFilters } from 'utils/product';

const { routes, category } = config;

const numbersMap: Record<ShopKey, NumberKey[] | undefined> = {
  biznesowe: undefined,
  dellowo: ['DELLOWO_START_YEAR'],
  premiumStation: undefined,
  premiumSerwer: undefined,
  cocon: undefined,
  firewall: undefined,
  rugged: undefined,
};

const articleCategoriesMap: Record<ShopKey, string[] | undefined> = {
  biznesowe: ['biznesowe-poradniki'],
  dellowo: ['baza-wiedzy'],
  premiumStation: ['blog-it'],
  premiumSerwer: ['baza-wiedzy'],
  cocon: [''],
  firewall: [''],
  rugged: [''],
};

type Result = PageLayoutServerSideProps & HomePageProps;

export const getServerSideProps: GetServerSideProps<Result> = withShopContext(async ({ res }, { client, shopId }) => {
  const transaction = startTransaction({ op: 'server.requests', name: routes.home.href });

  const serverRequests: RequestConfig[] = [
    ...pageLayoutServerRequests,
    testimonialRequestConfig,
    trustedUsCountRequestConfig,
    variantsPromotedCountRequestConfig,
    pageRequestConfig,
    pageFilesRequestConfig,
    tileMenuRequestConfig,
    bannersRequestConfig,
    articleCategoriesWithArticlesRequestConfig,
  ];

  const shopKey = getShopKey(shopId);

  if (shopKey === 'dellowo') {
    serverRequests.push(numbersRequestConfig);
  }

  const articleCategories = articleCategoriesMap[shopKey];

  const combinedDataSpan = transaction.startChild({ op: 'combined-data' });

  const { data: combinedData } = await graphQLRequest<
    GetPageLayoutResult &
      GetTestimonialsResult &
      GetTrustedUsCountResults &
      GetVariantsPromotedCountResults &
      GetPageResults &
      GetPagesFilesResults &
      GetBannersResults &
      GetTileMenuResult &
      Partial<GetNumbersResults & GetArticleCategoriesWithArticlesResult>,
    GetTrustedUsCountVariables &
      GetVariantsPromotedCountVariables &
      GetPageVariables &
      GetPagesFilesVariables &
      Partial<GetNumbersVariables & GetArticleCategoriesWithArticlesVariables>
  >(client, combineRequest(serverRequests), {
    variables: {
      searchAliasComparisonExpression: getComparisonExpression(),
      pages: [routes.home.href],
      numbers: numbersMap[shopKey],
      articleCategoriesSlug: articleCategories ? { _in: articleCategories } : undefined,
      articleCategoriesLimit: articleCategories ? 4 : undefined,
    },
  });

  combinedDataSpan.finish();

  if (!combinedData) {
    transaction.finish();
    return handleError(res, 500);
  }

  const menuTileData = await getMenuTileData(client, transaction, combinedData.tileMenu || []);
  let searchData;
  if (shopKey === 'premiumSerwer') {
    const constantFilter = { key: 'product_type', values: ['Serwer konfigurowalny'] };
    const { data: searchWithFiltersData } = await serverApiFetch(
      searchWithFiltersRoute,
      {
        name: '',
        mode: 'category',
        limit: category.secretSizeLimit,
        offset: 0,
        order: premiumSerwerSortValues[SortId.Popularity],
        filters: [constantFilter],
      },
      { headers: { 'x-commerce-shop-id': shopId } }
    );
    searchData = {
      ...searchWithFiltersData,
      constantFilters: [
        constantFilter,
        ...getSearchConstantFilters(searchWithFiltersData?.hits || 0, searchWithFiltersData?.filters || []),
      ],
    };
  }

  transaction.finish();
  return {
    props: {
      ...omit(combinedData, ['tileMenu']),
      searchData,
      homepageProducts: mapMenuToHomepageProducts(combinedData.tileMenu || [], menuTileData, shopId),
    },
  };
});

const Home = ({
  searchData,
  trustedUsCount,
  variantsPromotedCount,
  testimonials,
  homepageProducts,
  numbers,
  articleCategories,
  banners,
}: InferGetServerSidePropsType<typeof getServerSideProps>): JSX.Element => (
  <HomePage
    trustedUsCount={trustedUsCount}
    variantsPromotedCount={variantsPromotedCount}
    testimonials={testimonials}
    articleCategories={articleCategories}
    homepageProducts={homepageProducts}
    numbers={numbers}
    banners={banners}
    searchData={searchData}
  />
);

export default withPageLayout(Home);
