import Image from '@next/image';
import classNames from 'classnames';

import { getTrustedUsRoute } from 'apiRoutes/trustedUs';
import Button from '@components/atoms/Button';
import LargeHeading from '@components/atoms/LargeHeading';
import SkeletonLoader from '@components/atoms/SkeletonLoader';
import { FormattedMessage } from 'utils/intl';
import { getNumber } from 'utils/getNumber';
import { useClientSideProps } from 'hooks/useClientSideProps';
import useBoolean from 'hooks/useBoolean';
import useFetch from 'hooks/useFetch';

import messages from './TrustedUs.messages';
import classes from './TrustedUs.module.scss';

type Props = {
  page: string;
  limit: 12 | 18;
};

const TrustedUs = ({ page, limit }: Props): JSX.Element => {
  const [getTrustedUs] = useFetch(getTrustedUsRoute);

  const [isOpened, { toggle }] = useBoolean(false);

  const {
    isLoading,
    data: { trustedUsData },
  } = useClientSideProps({
    trustedUsData: () => getTrustedUs({ page }),
  });

  const logos = trustedUsData?.data?.trustedUsLogos || [];
  const numbers = trustedUsData?.data?.numbers || [];

  return logos.length || isLoading ? (
    <section className={classes.trustedUs}>
      <div className={classes.headerWrapper}>
        <LargeHeading
          title={<FormattedMessage {...messages.mainHeader} values={{ clients: getNumber(numbers, 'CLIENTS') }} />}
          subtitle={<FormattedMessage {...messages.mainDescription} />}
        />
      </div>
      <div className={classNames(classes.wrapper, classes[`wrapper${limit}`], { [classes.opened]: isOpened })}>
        {!isLoading ? (
          <div className={classes.logos}>
            {logos.map(({ id, image, name }) => (
              <div key={id} className={classes.logo}>
                <Image
                  objectFit="contain"
                  title={name}
                  alt={name}
                  resolveConfig={{
                    preset: 'customer_logo',
                    productName: 'customer_logo',
                    extension: image.extension,
                    name: image.basename,
                    description: name,
                  }}
                />
              </div>
            ))}
          </div>
        ) : (
          <SkeletonLoader className={classes.skeleton} iconWidth={250} />
        )}
      </div>
      {!isLoading && (
        <div className={classes.buttonWrapper}>
          <Button className={classes.button} color="secondary" variant="outlined" onClick={toggle}>
            <FormattedMessage {...(isOpened ? messages.showLess : messages.showMore)} />
          </Button>
        </div>
      )}
    </section>
  ) : (
    <></>
  );
};

export default TrustedUs;
