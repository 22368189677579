import { parse, ParsedUrlQuery } from 'querystring';

import { BannerTypeId } from 'types/models/Banner';
import { GetBannersResults } from 'graphql/queries/GetBanners.query';
import { Promotion } from '@components/PromotionBanners';
import getUniqueValues from 'utils/getUniqueValues';
import keysOf from 'utils/keysOf';

const getMappedPromotions = (
  banners: GetBannersResults['banners'],
  desktopBannerTypeId: BannerTypeId,
  query?: ParsedUrlQuery
): Promotion[] => {
  const promotions = getUniqueValues(banners, 'name')
    .filter(({ searchAlias }) => {
      const aliasQueryParams = searchAlias?.searchParams ? parse(searchAlias?.searchParams) : undefined;
      return (
        !aliasQueryParams ||
        keysOf(aliasQueryParams).every((key) => aliasQueryParams?.[key] && aliasQueryParams?.[key] === query?.[key])
      );
    })
    .map(({ id, name, url, openInNewTab }) => {
      const desktopImage = banners.find(
        ({ name: bannerName, bannerTypeId }) => bannerName === name && bannerTypeId === desktopBannerTypeId
      )?.image;
      const mobileImage = banners.find(
        ({ name: bannerName, bannerTypeId }) => bannerName === name && bannerTypeId === 'SMALL'
      )?.image;
      return { id, name, url, desktopImage, mobileImage, openInNewTab };
    });
  return promotions;
};

export default getMappedPromotions;
