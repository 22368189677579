import { ShopKey } from '@providers/ConfigProvider';

import { TextSection } from './SearchEngineText.types';
import messages from './SearchEngineText.messages';

export const textMap: Record<ShopKey, TextSection[]> = {
  biznesowe: [
    {
      header: messages.specializedPartnerHeader,
      paragraphs: [messages.specializedPartnerDescription],
    },
    {
      header: messages.richOfferHeader,
      paragraphs: [messages.richOfferDescription],
    },
    {
      header: messages.factoryNewHeader,
      paragraphs: [messages.factoryNewDescriptionFirstPart, messages.factoryNewDescriptionSecondPart],
    },
    {
      header: messages.freeDeliveryHeader,
      paragraphs: [messages.freeDeliveryDescription],
    },
    {
      header: messages.preorderHeader,
      paragraphs: [messages.preorderDescription],
    },
  ],
  dellowo: [
    {
      header: messages.preferredOfficialPartnerHeader,
      paragraphs: [messages.preferredOfficialPartnerDescription],
    },
    {
      header: messages.hardwareForCompaniesHeader,
      paragraphs: [messages.hardwareForCompaniesDescription],
    },
    {
      header: messages.dellFactoryNewHeader,
      paragraphs: [messages.dellFactoryNewDescriptionFirstPart, messages.dellFactoryNewDescriptionSecondPart],
    },
    {
      header: messages.dellowoFreeDeliveryHeader,
      paragraphs: [messages.dellowoFreeDeliveryDescription],
    },
    {
      header: messages.dellowoPreorderHeader,
      paragraphs: [messages.dellowoPreorderDescription],
    },
    {
      header: messages.titanPartnerHeader,
      paragraphs: [messages.titanPartnerDescription],
    },
  ],
  premiumStation: [],
  premiumSerwer: [],
  cocon: [],
  firewall: [
    {
      header: messages.welcomeFirewallHeader,
      paragraphs: [messages.welcomeFirewallDescriptionFirstPart, messages.welcomeFirewallDescriptionSecondPart],
    },
    {
      header: messages.firewallSpecializedHeader,
      paragraphs: [messages.firewallSpecializedDescription],
    },
    {
      header: messages.firewallEquipmentHeader,
      paragraphs: [messages.firewallEquipmentDescription],
    },
    {
      header: messages.firewallGuaranteeHeader,
      paragraphs: [messages.firewallGuaranteeDescription],
    },
    {
      header: messages.firewallShipmentHeader,
      paragraphs: [messages.firewallShipmentDescription],
    },
    {
      header: messages.firewallSupportHeader,
      paragraphs: [messages.firewallSupportDescription],
    },
    {
      header: messages.firewallPartnersHeader,
      paragraphs: [messages.firewallPartnersDescription],
    },
  ],
  rugged: [],
};
