import { ReactNode, useContext } from 'react';
import Image from '@next/image';
import classNames from 'classnames';

import { Classes } from 'types/Classes';
import { FilesContext } from '@providers/FilesProvider';
import Typography from '@components/atoms/Typography';
import { ResolveConfig } from 'utils/resolveImageUrl';

import classes from './ShopBanner.module.scss';

type Props = {
  classes?: Classes<'root' | 'header' | 'images' | 'image'>;
  images: string[];
  additionalContent?: ReactNode;
  header: ReactNode;
  description: ReactNode;
};

const ShopBanner = ({ classes: customClasses, images, additionalContent, header, description }: Props): JSX.Element => {
  const { getPageImageConfigs } = useContext(FilesContext);
  const imagesConfigs = getPageImageConfigs(images);
  const hasAllImages = imagesConfigs.every((config) => !!config);

  return hasAllImages ? (
    <div className={classNames(classes.wrapper, customClasses?.root)}>
      <div className={classNames(classes.imagesWrapper, customClasses?.images)}>
        {(imagesConfigs as ResolveConfig[]).map((image) => (
          <Image
            key={image.name}
            alt={image.name || ''}
            display="flex"
            layout="fill"
            objectFit="contain"
            className={classNames(classes.image, customClasses?.image)}
            resolveConfig={image}
            hasLazyLoad={false}
          />
        ))}
        {additionalContent && (
          <Typography variant="caption2" className={classNames(classes.logoText, classes.lineHeight)} renderAs="div">
            {additionalContent}
          </Typography>
        )}
      </div>
      <div className={classes.descriptionWrapper}>
        <Typography
          className={classNames(classes.header, customClasses?.header)}
          variant="body2"
          weight="700"
          renderAs="h1"
        >
          {header}
        </Typography>
        <Typography variant="caption2" className={classes.lineHeight} renderAs="div">
          {description}
        </Typography>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ShopBanner;
